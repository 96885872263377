import { getDeviceGroup } from 'tbSrc/js/utils';

const getNav = (data, deviceGroup) => {
    const { active, i18n, links, selectors } = data;
    const navItem = (data) => {
        const { id, href, text, className, required = false } = data;
        const selected = active.indexOf(href) !== -1;

        return deviceGroup === 'xs'
            ? `
                <option ${className ? `class="${className}"` : ''} ${selected ? 'selected' : ''} value="${href}" id="${id}">
                    ${text} ${required ? `(${i18n.missingInfo})` : ''}
                </option>
            ` : `
                <a class="list-group-item ${className || ''} ${selected ? 'active' : ''}" href="${href}" id="${id}">
                    ${text} ${required ? `<span class="required" />` : ''}
                </a>
            `;
    };
    const navItems = links.map((item) => navItem(item)).join('');

    return deviceGroup === 'xs'
        ? `
            <select class="form-select mb-sm" id="${selectors.navId}">
                ${navItems}
            </select>
        ` : `
            <nav class="list-group mb-xs" id="${selectors.navId}">
                ${navItems}
            </nav>
        `;
};

export default (args) => {
    // console.log(args);
    const { selectors } = args;
    const container = document.getElementById(args.selectors.asideId);
    const deviceGroup = getDeviceGroup();

    if (container) {
        const nav = getNav(args, deviceGroup);
        container.insertAdjacentHTML('afterbegin', nav);

        if (deviceGroup === 'xs') {
            container
                .querySelector(`#${selectors.navId}`)
                .addEventListener('change', (e) => {
                    location.href = e.target.value;
                });
        }
    }
};
