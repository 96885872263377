import escape from 'lodash.escape';
import { getRndHash } from 'tbSrc/js/utils';

const htmlTpl = ({ cname, title, verdict }) => {
    return `
        <dl class="password-info">
            <dt class="password-info__title">${title}</dt>
            <dd class="password-info__body ${cname}">${verdict}</dd>
        </dl>
    `;
};

function setupRenderer(wrapID, formGroup) {
    const wrapTpl = `<div id="${wrapID}" style="display:none" />`;

    if (formGroup.length < 1) {
        console.warn('Missing parent wrapper with class "form-group"!');
    }

    return (params) => {
        const { isVisible } = params;
        let verdictContainer = formGroup.querySelector(`#${wrapID}`);

        if (!verdictContainer || verdictContainer.length < 1) {
            formGroup.insertAdjacentHTML('beforeend', wrapTpl);
            verdictContainer = formGroup.querySelector(`#${wrapID}`);
        }

        verdictContainer.innerHTML = htmlTpl(params);
        verdictContainer.style.display = isVisible ? 'block' : 'none';
    };
}

function checkPass(params) {
    const { i18n, render } = params;
    const password = this.value;
    let strength = 0;
    let data = {
        cname: 'password-short',
        isVisible: true,
        verdict: i18n.short,
        title: i18n.passStrengthText
    };

    if (password.length === 0) {
        render({
            ...data,
            isVisible: false
        });
        return this;
    }

    render({
        ...data,
        isVisible: true
    });

    /* Detect non ascii chars */
    /* \x00-\x7F - refers to a character range outside ASCII */
    /* @see https://stackoverflow.com/a/2124144 */
    /* @see \Inbox\ValidationBundle\Validator\Constraints\SecurityStringValidator */
    // eslint-disable-next-line no-control-regex
    let matches = password.match(/[^\x00-\x7F]/g);
    if (matches) {
        render({
            ...data,
            cname: 'password-short',
            verdict: i18n.bad + escape(matches.join(''))
        });

        return this;
    }

    if (password.length < 6) {
        render({
            ...data,
            cname: 'password-short',
            verdict: i18n.short
        });
        return this;
    }
    if (password.length > 7) strength += 1;
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/))  strength += 1;
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/))  strength += 1;
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/))  strength += 1;
    if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1;
    if (strength < 2 ) {
        data = {
            ...data,
            cname: 'password-weak',
            verdict: i18n.weak
        };
    }
    else if (strength == 2 ) {
        data = {
            ...data,
            cname: 'password-good',
            verdict: i18n.good
        };
    }
    else {
        data = {
            ...data,
            cname: 'password-strong',
            verdict: i18n.strong
        };
    }
    render(data);
    this.dataset.strength = strength;
    return this;
}

export default (params) => {
    // console.log(params);
    const passInput = document.getElementById(params.passInputID.slice(1));
    const formGroup = passInput.closest('.row');
    const wrapID = `wrap-${params.passInputID.slice(1)}_${getRndHash()}`;
    const opts = {
        i18n: {
            good:   'Good',
            short:  'Too short',
            strong: 'Strong',
            weak:   'Weak',
            ...params.i18n
        },
        render: setupRenderer(wrapID, formGroup)
    };

    passInput.addEventListener('change', () => {
        checkPass.call(passInput, opts);
    });
    passInput.addEventListener('keyup', () => {
        checkPass.call(passInput, opts);
    });

    return passInput;
};
