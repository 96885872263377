/* global bootstrap */
import { getRndHash } from 'tbSrc/js/utils';
import Alert from 'tbSrc/js/modules/alert';
import modalTemplate from '../templates/modal';

const rHash = getRndHash(10);
const prefix = 'inx-modal';
const defaults = {
    // Use dialog alert
    alertID: [prefix, '-alert_', rHash].join(''),
    alertEnabled: false,
    // Body - if null no html markup for body
    body: '',
    // Array of btn objects
    btn: [
        {
            cName: 'secondary',
            isClose: true,
            text: 'Ok',
            type: 'button',
        }
    ],
    // Additional classes
    className: '',
    // Modal ID
    id: [prefix + '_', rHash].join(''),
    // Closes the modal when escape key is pressed
    keyboard: true,
    // Show "close" button in modal header
    showClose: true,
    closeClass: 'close',
    // Bootstrap standard classes: modal-sm or modal-lg
    size: '',
    // Set static=true for a backdrop which doesn't close the modal on click.
    static: false,
    // Additional styles for the .modal-dialog class
    style: '',
    title: 'Dialog title',
    onClick: function (btn) {
        this.close();
    }
};
// Private data
const pd = new WeakMap();

function clickHandler(modal, callback) {
    const oData = pd.get(modal);
    const { handlerAttached, renderedEl } = oData;

    if (handlerAttached) {
        return renderedEl;
    }

    renderedEl.querySelector('.modal-content').querySelectorAll('.btn, .close').forEach((el) => {
        el.addEventListener('click', function (e) {
            if (typeof(callback) === 'function') {
                e.preventDefault();
                callback.call(modal, el, e);
            }
        });
    });

    renderedEl.addEventListener('hidden.bs.modal', function () {
        renderedEl.remove();
        // renderedEl.parentNode.removeChild(renderedEl);
    });

    pd.set(modal, {
        ...oData,
        renderedEl,
        handlerAttached: true
    });

    return renderedEl;
}

class Modal {
    constructor(args) {
        const opts = { ...defaults, ...args };

        pd.set(this, {
            handlerAttached:  false,         // is button handler attached
            tplFn:            modalTemplate, // tpl function
            renderedEl:        false,         // result of rendering
            opts                             // params
        });

        if (opts.alertEnabled) {
            this.flash = () => new Alert('#' + opts.alertID);
        }
    }

    close(onCloseCallback) {
        const { id } = pd.get(this).opts;

        const myModal = bootstrap.Modal.getOrCreateInstance(document.querySelector(`#${id}`));
        myModal.hide();

        if (typeof(onCloseCallback) === 'function') {
            onCloseCallback();
        }
    }

    render() {
        let { renderedEl, opts: data, tplFn } = pd.get(this);

        if (!renderedEl) {

            //create new el from html
            const el = document.createElement('div');
            el.innerHTML = tplFn(data);
            const htmlEl = el.firstChild;

            pd.set(this, { ...pd.get(this), renderedEl: htmlEl });
        }

        return clickHandler(this, data.onClick);
    }

    show() {
        const { id } = pd.get(this).opts;

        const modalHtml = this.render();
        document.body.appendChild(modalHtml);

        // const modalEl = document.querySelector('#' + id);
        const myModal = bootstrap.Modal.getOrCreateInstance('#' + id);
        myModal.show();

        setTimeout(() => document.querySelector('#' + id).classList.remove('fade'), 400);
        return this;
    }
    getId() {
        const { id } = pd.get(this).opts;

        return id;
    }
}

export default Modal;
