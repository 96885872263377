/* global inboxHtmlLoadTime */
import { getRandomInt } from 'tbSrc/js/utils';

export default (imgPref, isBizmail) => {
    let body         = document.querySelector("body"),
        imgUrl       = [imgPref, 'img/bg/', getRandomInt(1, 111), '.jpg'].join(''),
        htmlTag      = document.querySelector('html'),
        htmlOldStyle = htmlTag.style.cssText,
        htmlStyle    =  '';

    //load image only if personal login, desktop and fast internet
    if (
        !isBizmail &&
        window.innerWidth >= 720
    ) {
        htmlStyle = 'background:url(\'' + imgUrl + '\') center no-repeat;background-size:cover';
    }

    //do not load background image on slow internet (between html load and calling this code is more than 10 seconds)
    if (window.inboxHtmlLoadTime) {
        const dateDiff = Math.abs(Date.now() - inboxHtmlLoadTime)/1000;
        if (dateDiff > 6) {
            htmlStyle = '';
        }
    }

    if (htmlOldStyle.trim().length > 0) {
        htmlStyle = [htmlOldStyle, htmlStyle].join(';');
    }
    body.style.background = 'none transparent';
    htmlTag.setAttribute('style', htmlStyle);
};
