import inx from 'inx';
import INBOX from 'INBOX';

const main = new Promise((resolve, reject) => {
    let globalVars = new Promise((resolve, reject) => {
        let tOut = setInterval(() => {
            if (typeof(inx) === 'object') {
                resolve(inx);
                clearInterval(tOut);
            }
            // console.info('waiting for inx');
        }, 1);
    });

    let nameSpace = new Promise((resolve, reject) => {
        let tOut = setInterval(() => {
            if (typeof(INBOX) === 'object') {
                resolve(INBOX);
                clearInterval(tOut);
            }
            // console.info('waiting for INBOX');
        }, 1);
    });

    Promise
        .all([globalVars, nameSpace])
        .then(([inx, INBOX]) => {
            resolve({ INBOX, inx });
        })
        .catch((err) => {
            console.warn(err);
        });
});

export default main;