const body = (o) => {
    return o.body === null
        ? ''
        : `<div class="modal-body">
                ${o.alertEnabled ? `<div id="${o.alertID}"></div>` : ''}
                ${o.body}
            </div>`;
};

const buttons = (btnData) => {
    return btnData.map((btn) => {
        const btnType = btn.type || 'button';
        return btnType !== 'link'
            ? `
                <button
                    class="btn btn-${btn.cName || 'secondary'}"
                    ${btn.isClose ? 'data-bs-dismiss="modal" data-dismiss="modal"' : ''}
                    type="${btnType}"
                >${btn.text}</button>
            ` : `
                <a
                    class="btn btn-${btn.cName || 'secondary'}"
                    ${btn.isClose ? 'data-bs-dismiss="modal" data-dismiss="modal"' : ''}
                    href="${btn.href}"
                    ${btn.target === 'blank' ? 'target="_blank"' : ''}
                >${btn.text}</a>
            `;
    }).join('');
};

const closeButton = (show, closeClass) => show
    ? `
        <button type="button" class="${closeClass}"  data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    ` : '';


const modalTemplate = (o) => `<div
        aria-labelledby="${o.id}"
        class="modal ${o.className} fade"
        ${o.static ? 'data-backdrop="static" data-bs-backdrop="static"' : ''}
        ${!o.keyboard ? 'data-keyboard="false" data-bs-keyboard="false"' : ''}
        id="${o.id}"
        role="dialog"
        tabindex="-1"
    >
        <div class="modal-dialog ${o.size}" role="document"${ o.style ? ` style="${o.style}"` : ''}>
            <div class="modal-content">
                <div class="modal-header">
                    ${closeButton(o.showClose, o.closeClass)}
                    <h4 class="modal-title ${ o.showClose ? '' : ' w-100'}" id="myModalLabel">${o.title}</h4>
                </div>
                ${body(o)}
                <div class="modal-footer">
                    ${buttons(o.btn)}
                </div>
            </div>
        </div>
    </div>`;

export default modalTemplate;
