// Site wide functions here
import domready from 'domready';
import tippy from './tippy';
import showSiteNav from './site-navigation';

const toolTipSelector = '.has-tooltip, .ifi-sign-question';

function init(args) {
    // console.log(args);
    const { login } = args;
    domready(() => {
        const { siteNav } = login;

        // Show left aside
        if (siteNav) {
            showSiteNav(siteNav);
        }

        // Prepare tooltips
        tippy(toolTipSelector, {
            allowHTML: true,
            arrow: true,
            theme: 'light-border'
        });
    });
}

export default init;
